<template>
<v-col>
  
  <v-row style="padding-bottom:50px;" class="dashboard">
    <v-col cols="11" sm="12" md="12" lg="12" class="mx-auto header-section text-center mt-5 mb-5">
        <v-img src="../../../assets/img/monnaie_pirate_logo.png" class="d-none d-sm-inline-flex" width="230"/>
        <v-img src="../../../assets/img/monnaie_pirate_logo.png" class="d-inline-flex d-sm-none" width="150"/>
        <h1 class="font-main pb-1 pt-0 mb-5 pb-5 yellow--text text--darken-2">
          La monnaie pirate
        </h1>
    </v-col>

    <!-- <v-col cols="11" sm="11" md="8" lg="7" class="mx-auto pb-0 d-none d-md-flex" 
           style="border-top:1px dashed #fbc02d;margin-top:10px;"></v-col> -->

    <v-col cols="11" sm="11" md="8" lg="8" class="mx-auto pt-0">
      <v-row>
        
        <v-col cols="12" sm="6" v-if="!$store.state.auth.isLogin">
          <v-card to="/login" 
                class="game-menu-btn text-center" elevation="0">
            <v-icon x-large color="primary">mdi-login</v-icon><br>
            <v-card-title class="font-main dash-title"> Se connecter</v-card-title>
            <v-card-text class="font-papyrus mt-2">
              Accédez à votre compte<br>pour gérer votre monnaie<br>et accéder à tous les outils.
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" v-if="!$store.state.auth.isLogin">
          <v-card to="/register" 
                class="game-menu-btn text-center" elevation="0">
            <v-icon x-large color="primary">mdi-account-plus</v-icon><br>
            <v-card-title class="font-main dash-title">S'inscrire</v-card-title>
            <v-card-text class="font-papyrus mt-2">
              Créez votre compte gratuit<br>en quelques secondes, et recevez<br>vos 10 premiers PRT aujourd'hui !
            </v-card-text>
          </v-card>
        </v-col>

        <template v-if="$store.state.auth.isLogin">
          <v-col cols="12" md="6" class="mt-5">
            <WalletCard :wallet="$store.state.auth.user.walletMain"></WalletCard>
          </v-col>
          <v-col cols="12" v-for="wallet in $store.state.auth.user.walletsDeposit" :key="wallet._id"
                :md="$store.state.auth.user.walletsDeposit.length <= 2 ? 6 : 4" class="mt-5">
            <WalletCard :wallet="wallet"></WalletCard>
          </v-col>
          <v-col v-for="company in $store.state.auth.user.companies" :key="company._id" class="mt-5">
            <WalletCard :wallet="company.walletDeposit" :ownerCompany="company"></WalletCard>
          </v-col>
        </template>
        

        <v-col cols="12" sm="6" v-if="$store.state.auth.isLogin">
          <v-card to="/market" 
                class="game-menu-btn text-center" elevation="0">
            <v-icon x-large color="primary">mdi-cart-arrow-right</v-icon><br>
            <v-card-title class="font-main dash-title"> Marché pirate</v-card-title>
            <v-card-text class="font-papyrus mt-2">
              Échangez ce que vous voulez en Monnaie Pirate !
              Parcourez les annonces des autres pirates,
              et publiez vos propres annonces en PRT !
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" v-if="$store.state.auth.isLogin">
          <v-card to="/publish-offer" 
                class="game-menu-btn text-center" elevation="0">
            <v-icon x-large color="primary">mdi-file-plus-outline</v-icon><br>
            <v-card-title class="font-main dash-title"> Créer une annonce</v-card-title>
            <v-card-text class="font-papyrus mt-2">
              Vous avez quelque chose à vendre en PRT ?
              Publiez votre annonce dès maintenant, et faites en profiter les autres pirates !
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" v-if="$store.state.auth.isLogin">
          <v-card to="/map" 
                class="game-menu-btn text-center" elevation="0">
            <v-icon x-large color="primary">mdi-map-search-outline</v-icon><br>
            <v-card-title class="font-main dash-title"> La carte</v-card-title>
            <v-card-text class="font-papyrus mt-2">
              Explorez la carte pour découvrir les pirates qui se trouvent autour de vous,
              et repérer les lieux où dépenser vos PRT !
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" v-if="$store.state.auth.isLogin" >
          <v-card to="/pay" class="game-menu-btn text-center" elevation="0">
            <v-img src="../../../assets/img/coin-badge.jpg" class="d-inline-block" style="border-radius:100%;margin-bottom:-50px;" width="60"/>
            <br>
            <v-card-title class="font-main dash-title">Devenir membre d'équipage</v-card-title>
            <v-card-text class="font-papyrus mt-2">
             Participez au financement de la Monnaie Pirate pour obtenir votre badge
             et accéder aux parties multijoueurs...
              
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" v-if="$store.state.auth.isLogin">
          <v-card  to="/share" class="game-menu-btn text-center" elevation="0">
            <v-icon x-large color="primary">mdi-ear-hearing</v-icon><br>
            <v-card-title class="font-main dash-title primary--text"> Bouche à oreille</v-card-title>
            <v-card-text class="font-papyrus mt-2 primary--text">
              Plein de contenus à partager sur les réseaux, ou à imprimer,
              pour faire marcher le bouche à oreille, et faire connaître la Monnaie Pirate
              le plus largement possible !
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" v-if="$store.state.auth.isLogin" >
          <v-card to="/recruit" style="border-color:red"
                class="game-menu-btn text-center" elevation="0">
            <v-icon x-large color="red">mdi-qrcode</v-icon><br>
            <v-card-title class="font-main dash-title red--text">Parrainage</v-card-title>
            <v-card-text class="font-papyrus mt-2 red--text">
              Partagez votre code de parrainage pour recruter de nouveaux pirates !<br>
              Gagnez 1 PRT à chaque nouvelle inscription !<br>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>

  <v-row>
    <v-col class="pa-0">
      <Footer/>
    </v-col>
  </v-row>
</v-col>
</template>

<style>
  .dashboard .game-menu-btn{
    border: 1px dashed #fbc02d !important;
    height:unset!important;
    min-height:350px!important;
  }
  .dashboard .game-menu-btn i{
    margin-bottom:-60px;
  }
  .dashboard .dash-title{
    font-size:45px;
    margin-bottom:30px;
  }

  @media (max-width: 960px){
    .dashboard .game-menu-btn {
      height:unset!important;
      min-height:300px!important;
    } 
  }
</style>

<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

//import Post from '@/components/Post.vue'
import Footer from '@/components/main-layout/Footer.vue'
import WalletCard from '@/components/versions/wallet/WalletCard.vue'

export default {
  name: 'dashboard',
  components: { Footer, WalletCard },
  data: () => ({
    
    
  }),
  async mounted(){
    this.$store.dispatch('app/incStat', '/dashboard')
  },
  methods: {
    

  },
  computed: {
    
    
  }
}
</script>
