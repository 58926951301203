<template>
  <v-card elevation="0" class="pa-1 card-wallet" @click="openWallet(wallet)">
    <v-col cols="12" class="px-1 pr-3">
      
      <div class="wallet-owner-name">
          <v-chip color="grey darken-4" class="ml-1 my-0 py-0 pl-1 pr-4" 
                  title="propriétaire de ce compte" 
                  v-if="wallet.ownerCompany == null">
            <v-gravatar :height="25" :width="26" class="mr-1" 
                        :default-img="'retro'" style="border-radius:50%" 
                        :email="$store.state.auth.user.name"/>
            <v-icon small class="mr-1">mdi-account</v-icon>
            <b>{{ $store.state.auth.user.name }}</b>
          </v-chip>
          <v-chip :color="ownerCompany.category.iconColor" outlined class="ml-1 my-2 py-0 pl-1 pr-4" 
                  title="propriétaire de ce compte" v-else>
            <v-gravatar :height="25" :width="26" class="mr-1" 
                        :default-img="'retro'" style="border-radius:50%" 
                        :email="ownerCompany.name"/>
            <v-icon small class="mr-1">mdi-briefcase</v-icon>
            <b>{{ ownerCompany.name }}</b>
          </v-chip>
      </div>

      <v-card-title class="mt-2 pt-0 pl-3">
        n° {{ wallet.uid }}
      </v-card-title>
      
      <v-card-subtitle v-if="wallet.type=='MAIN'" class="pl-3">
        <v-icon small class="mr-2">mdi-credit-card-clock</v-icon>
        Compte courant
      </v-card-subtitle>
      <v-card-subtitle v-if="wallet.type=='DEPOSIT'" class="pl-3">
        <v-icon small class="mr-2">mdi-credit-card-settings</v-icon>
        <template v-if="wallet.name == 'DEPOSIT'">Compte de dépot</template>
        <template v-else>{{ wallet.name }}</template>
      </v-card-subtitle>


      <v-card-text class="pl-3 pb-0">
        <template v-if="wallet.type == 'MAIN'">
          <span class="mr-2 font-led primary--text" style="font-size:28px;">
            <b>{{ convertUnityMony(wallet.amountUnity).toFixed(4) }} {{ $store.state.app.monyUnity }}</b>
          </span>
          <div class="d-inline-block">~{{ monyToEuro(convertUnityMony(wallet.amountUnity)).toLocaleString() }} €</div>
        </template>
        <template v-if="wallet.type == 'DEPOSIT'">
          <span class="mr-2 font-led primary--text" style="font-size:28px;">
            <b>{{ wallet.amountMony.toFixed(4) }} {{ $store.state.app.monyUnity }}</b>
          </span>
          <div class="d-inline-block">~{{ monyToEuro(wallet.amountMony).toFixed(2) }} €</div>
        </template>
      </v-card-text>

    </v-col>

    <v-dialog v-model="showWalletTransaction"
              max-width="800" content-class="dialog-large-bordered" scrollable
              :fullscreen="$vuetify.breakpoint.width < 960">
      <v-card>
        <v-card-title class="text-center">
          Compte n° {{ wallet.uid }}<br>
          <span class="primary--text font-papyrus">{{ ownerName }}</span>
        </v-card-title>
        <v-btn class="d-md-none mx-5 mb-5" @click="showWalletTransaction = false">Fermer</v-btn>
        <v-divider></v-divider>
        <v-card-title class="font2x font-led primary--text text-center">
          {{ convertUnityMony(wallet.amountUnity).toFixed(4) }} PRT
        </v-card-title>

        <div class="d-block" style="height:200px;" v-if="showWalletTransactionOk">
        <ChartWallet :height="200"></ChartWallet> 
        </div>
        
        <v-card-text class="py-3">
          <template v-for="(transaction, nTrans) in wallet.transactions">
            <template v-if="nTrans < 366">
              <TransactionCard :key="nTrans" :transaction="transaction" :showDaily="true"></TransactionCard>
            </template>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<style >
.wallet-owner-name b{
  font-size:17px !important;
}
.v-card.card-wallet{
  /* border:1px solid #313131 !important; */
  border:1px dashed #fbc02d !important;
  background-color: #0e0d0d
}
</style>

<script>

// import axios from "axios"
// import { i18n } from 'vue-lang-router';
import core from '../../../plugins/core.js'
import router from '../../../router/router'
const config = require('../../../config/' + process.env.NODE_ENV)
import TransactionCard from '@/components/wallet/TransactionCard.vue'
import ChartWallet from '@/components/charts/ChartWallet.vue'
import Gravatar from 'vue-gravatar'

// import MoneySendButton from '@/components/wallet/MoneySendButton.vue'
// import MoneyReceiveButton from '@/components/wallet/MoneyReceiveButton.vue'

export default {
  name: 'WalletCard',
  components: { TransactionCard, ChartWallet, 'v-gravatar' : Gravatar  },
  props:['wallet', 'ownerName', 'ownerCompany'],
  data: () => ({
    showWalletTransaction: false,
    showWalletTransactionOk: false
  }),
  mounted: async function(){
    this.initData()
  },
  methods: {
    async initData(){
    },
    showDialogTransaction(){
      this.showWalletTransaction = true
      this.showWalletTransactionOk = false
      this.$store.dispatch("auth/setFocusWalletUid", { uid : this.wallet.uid })
      setTimeout(()=>{ this.showWalletTransactionOk = true }, 50)
    },
    monyToEuro(amount){ return amount * config.monyToEuro },

    convertUnityMony(amountUnity){
      return core.convertUnityMony(amountUnity, this.$store.state.auth.monyConvertValue)
    },
    convertMonyUnity(amountMony){
      return core.convertMonyUnity(amountMony, this.$store.state.auth.monyConvertValue)
    },
    openWallet(wallet){
      if(wallet.ownerCompany == null) router.push('/wallet/'+wallet.uid)
      else router.push('/company/'+this.ownerCompany._id)
    }
    
  },
  computed: {
  },
  watch: {
      
  }, 
}
</script>